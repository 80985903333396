
import { Component, AfterViewInit, ElementRef, ViewChild, OnInit } from '@angular/core';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css']
})
export class PartnersComponent implements OnInit, AfterViewInit {
  @ViewChild('iframeContent', { static: false }) iframe: ElementRef;
  constructor() { }

  ngAfterViewInit(): void {
    const iframeElement: HTMLIFrameElement = this.iframe.nativeElement;
    const targetUrl = 'https://blog.smeconnect.lk/our-partners/'
    iframeElement.src = targetUrl;

     // Wait for the iframe to load
     iframeElement.onload = () => {
      // Access the iframe's document
      const iframeDoc = iframeElement.contentDocument || iframeElement.contentWindow?.document;

      if (iframeDoc) {
        // Find the div with a specific ID
        const targetDiv = iframeDoc.getElementById('content');

        console.log(targetDiv)

        if (targetDiv) {
          
          const newIframeBody = iframeDoc.createElement('body');
          newIframeBody.appendChild(targetDiv.cloneNode(true));
          iframeDoc.body = newIframeBody;
        } else {
          console.error('Target div not found.');
        }
      } else {
        console.error('Failed to access iframe document.');
      }
    };
  }
  

  ngOnInit(): void {
  }

}
